<template>
	<div class="lump">
		<div>
			<p class="title">为何在后台或者锁屏状态下收不到消息,收不到异性的语音和视频聊天申请？</p>
			<p class="tips">选择所用机型,根据教程,开启相应权限,不错过每一段缘分。</p>
		</div>
		<div class="type-list">
			<router-link class="item" :to="'/oppoPhones?flag='+flag">
				<img src="@/assets/images/useExplain/oppo.png" alt="">
				<p style="color: #41A058;">oppo手机</p>
			</router-link>
			<router-link class="item" :to="'/vivoPhones?flag='+flag">
				<img src="@/assets/images/useExplain/vivo.png" alt="">
				<p style="color: #3F9ED5;">vivo手机</p>
			</router-link>
			<router-link class="item" :to="'/huaweiPhones?flag='+flag">
				<img src="@/assets/images/useExplain/huawei.png" alt="">
				<p style="color: #CC3A2F;">华为手机</p>
			</router-link>
			<router-link class="item" :to="'/miPhones?flag='+flag">
				<img src="@/assets/images/useExplain/mi.png" alt="">
				<p style="color: #DD7E29;">小米手机</p>
			</router-link>
			<router-link class="item" :to="'/meizuPhones?flag='+flag">
				<img src="@/assets/images/useExplain/meizu.png" alt="">
				<p style="color: #29DDBD;">魅族手机</p>
			</router-link>
			<router-link class="item" :to="'/iPhone?flag='+flag">
				<img src="@/assets/images/useExplain/ios.png" alt="">
				<p style="color: #000000;">苹果手机</p>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flag: 'true'
			}
		},
		methods: {
			getVersion(val) {
				let str = val.split('.')[0]
				if (str < 6) {
					this.flag = 'false'
				} else {
					this.flag = 'true'
				}
				// this.$toast(str)
			}
		},
		mounted() {
			// 将要给原生调用的方法挂载到 window 上面
			window.getVersion = this.getVersion
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		min-height 100vh
		background #fff
		div
			padding 40px 0.48rem 20px
			text-align left
			.title
				font-size 0.346666rem
				color #333333
				line-height 0.586666rem
				text-align left
			.tips
				font-size 0.346666rem
				color #AD49E6
				line-height 40px
		.type-list
			display flex
			justify-content flex-start
			align-items center
			flex-wrap wrap
			padding 0 10px
			.item
				margin-bottom 0.96rem
				width 33.33%
				text-align center
				img
					width 1.253333rem
					height 1.92rem
				p
					margin-top 0.32rem
					font-size 0.346666rem
					font-weight 500
</style>
