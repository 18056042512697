<template>
	<div class="lump">
		<div class="activity">
			<img class="entrance-img" src="@/assets/images/valentineDay/entrance-img.png" alt="">
			<img class="join-img" @click.stop="getYes" src="@/assets/images/valentineDay/btn-01.png" alt="">
		</div>
		<img class="close" @click="getNo" src="@/assets/images/valentineDay/close.png" alt="">
	</div>
</template>

<script>
	export default {
		data() {
			return {
				deviceInfo: ''
			}
		},
		methods: {
			// 参与活动
			getYes() {
				if (this.deviceInfo.indexOf('android') != -1) {
					androidWebKit.joinActivity()
				} else if (this.deviceInfo.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.joinActivity.postMessage("参加活动")
				}
			},
			// 关闭弹窗
			getNo() {
				if (this.deviceInfo.indexOf('android') != -1) {
					androidWebKit.close()
				} else if (this.deviceInfo.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.closeWindow.postMessage("关闭窗口")
				}
			}
		},
		mounted() {
			this.deviceInfo = navigator.userAgent.toLowerCase()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		display flex
		flex-direction column
		justify-content center
		align-items center
		width 100vw
		height 100vh
		background rgba(0, 0, 0, 0.5)
		.activity
			position relative
			.entrance-img
				width 7.013333rem
				height 10.293333rem
			.join-img
				position absolute
				left 50%
				bottom 0.613333rem
				transform translateX(-50%)
				width 3.653333rem
				height 0.906666rem
		.close
			margin-top 1.68rem
			width 0.613333rem
			height 0.613333rem
</style>
