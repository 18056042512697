<template>
	<div class="lump" :class="recordShow ? 'fixed' : ''">
		<img @click="recordShow = true" class="record" src="@/assets/images/luckDraw/record.png" alt="">
		<div class="banner">
			<img src="@/assets/images/luckDraw/main-banner.png" alt="">
		</div>
		<div class="main">
			<!-- 充值轮播 -->
			<notice />
			<!-- 翻牌抽奖 -->
			<!-- <brand /> -->
			<div class="horn-box brand-box">
				<img class="horn left" src="@/assets/images/luckDraw/horn-left.png" alt="">
				<img class="horn right" src="@/assets/images/luckDraw/horn-right.png" alt="">
				<div class="tips">
					<p class="tips-1">充值可获得翻牌机会，连抽中奖概率更大！</p>
					<p class="tips-2">剩余抽奖<span>[{{num}}]</span>次</p>
				</div>
				<ul>
					<li v-for="(item, index) in prizeList" :key="index">
						<img @click="getLuckDraw(index)" class="brand-img" :class="item.turn ? 'card' : ''" src="@/assets/images/luckDraw/brand.png" alt="">
						<img v-show="handShow && index == 4" class="hand" src="@/assets/images/luckDraw/hand.png" alt="">
						<div v-show="item.open" class="show-img">
							<img :src="item.imgUrl" alt="">
							<p>{{item.prizeName}}</p>
						</div>
					</li>
				</ul>
			</div>
			<!-- 龙虎榜单 -->
			<record />
			<!-- 充值说明 -->
			<recharge />
			<!-- 中奖说明 -->
			<explain />
		</div>
		<!-- 中奖纪录 -->
		<van-dialog v-model="recordShow" :showConfirmButton="false" width="90%">
			<div class="red-bor">
				<div v-if="recordList.length > 0" class="main main-1">
					<p class="title">中奖纪录</p>
					<div class="list">
						<van-list v-model="loading" :finished="finished" :immediate-check ='false' finished-text="暂无更多中奖记录" @load="getRecordList">
							<van-cell v-for="(item, index) in recordList" :key="index">
								<div class="item">
									<img :src="item.prizeImg" alt="">
									<p>{{item.prizeName}}</p>
								</div>
							</van-cell>
						</van-list>
					</div>
					<p @click="recordShow = false" class="btn">我知道了</p>
				</div>
				<div v-else class="main main-2">
					<p class="title">中奖纪录</p>
					<p class="tips-1">你暂无中奖记录，快去抽奖啦！</p>
					<p @click="recordShow = false" class="btn">去抽奖</p>
					<p @click="recordShow = false" class="tips-2">不了，谢谢</p>
				</div>
			</div>
		</van-dialog>
		<!-- 抽奖提示 -->
		<van-dialog v-model="resultShow" :showConfirmButton="false" closeOnClickOverlay width="90%">
			<div class="red-bor">
				<div v-if="state == 'none'" class="main main-2">
					<p class="title">次数不足哦~</p>
					<p @click="goRecharge" class="btn">去充值</p>
					<p @click="resultShow = false" class="tips-2">不了，谢谢</p>
				</div>
				<div v-else class="main main-3">
					<img :src="prizeInfo.imgUrl" alt="">
					<p>恭喜你！获得{{prizeInfo.prizeName}}</p>
				</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import notice from './components/notice.vue'
	import record from './components/record.vue'
	import recharge from './components/recharge.vue'
	import explain from './components/explain.vue'
	import Cookies from 'js-cookie'
	import { getUserLotteryRecordsApi, getUserChanceApi, getLuckDrawApi } from '@/request/api.js'
	export default {
		components: { notice, record, recharge, explain },
		data() {
			return {
				recordShow: false,
				loading: false,
				finished: false,
				resultShow: false,
				token: '',
				formData: {
					pageNumber: 1,
					pageSize: 17
				},
				recordList: [],
				num: 0,
				prizeInfo: {},
				handShow: true,
				prizeList: [
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false },
					{ imgUrl: '', prizeName: '', turn: false, open: false }
				],
				total: 0,
				state: 'have',
				flag: true
			}
		},
		methods: {
			updateRecord() {
				this.loading = false
				this.finished = false
				this.formData = {
					pageNumber: 1,
					pageSize: 17
				}
				this.recordList = []
				this.getRecordList()
			},
			// 用户个人中奖纪录
			getRecordList() {
				getUserLotteryRecordsApi(this.formData).then(res => {
					if (this.recordList.length === res.data.total) {
						this.finished = true
						return
					}
					this.formData.pageNumber ++
					res.data.records.map((item) => {
						this.recordList.push(item)
						return this.recordList
					})
					this.loading = false
				}).catch(err => {
					this.finished = true
					this.loading = false
					this.$toast(err.message)
				})
			},
			// 去充值
			goRecharge() {
				this.buryClick('次数不足充值按钮点击次数', 'Flop_frequency_recharge_male_click')
				this.$router.push({ path: '/recharge' })
			},
			// 获取抽奖次数
			getNum() {
				getUserChanceApi().then(res => {
					this.num = res.data
					this.updateRecord()
				})
			},
			// 点击抽奖
			getLuckDraw(index) {
				if (this.flag) {
					if (this.num == 0) {
						this.state = 'none'
						this.resultShow = true
						return false
					}
					this.flag = false
					this.buryClick('翻牌次数', 'Flop_male_click')
					getLuckDrawApi().then(res => {
						this.buryClick('翻牌成功次数', 'Flop_successfully_male_click')
						this.total ++
						this.getNum()
						this.prizeInfo = res.data
						this.handShow = false
						this.$set(this.prizeList[index], 'turn', true)
						this.$set(this.prizeList[index], 'imgUrl', res.data.imgUrl)
						this.$set(this.prizeList[index], 'prizeName', res.data.prizeName)
						setTimeout(() => {
							this.resultShow = true
						}, 800)
						setTimeout(() => {
							this.flag = true
							this.$set(this.prizeList[index], 'open', true)
						}, 1000)
						if (this.total == 9) {
							this.total = 0
							setTimeout(() => {
								this.prizeList = [
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false },
									{ imgUrl: '', prizeName: '', turn: false, open: false }
								]
							}, 1500)
						}
					}).catch(err => {
						this.flag = true
						this.$toast(err.message)
					})
				}
			},
			// 调用埋点方法
			buryClick(name, type) {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						if (error && error.toString() == 'ReferenceError: androidWebKit is not defined') {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
						} else {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
						}
					}
				}
			}
		},
		mounted() {
			this.token = this.$route.query.token
			Cookies.set('token', this.token, { expires: 1 })
			this.getNum()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		position relative
		padding-bottom 0.266666rem
		background #F43638
		.record
			position absolute
			top 0.72rem
			right 0.4rem
			width 1.973333rem
			height 0.586666rem
		.banner
			width 100%
			min-height 8.88rem
			img
				width 100%
				height auto
		.main
			padding 0 0.24rem
	.fixed
		position fixed !important
	.red-bor
		padding 9px 0
		background linear-gradient(0deg, #f91b39, #ff6c54)
		.main 
			margin 0 9px
			height 100%
			background #FFF6E6
			border-radius 0.32rem
			.title
				font-size 0.56rem
				color #333333
			.btn
				width 3.733333rem
				height 0.906666rem
				font-size 0.4rem
				color #fff
				font-weight 500
				text-align center
				line-height 0.96rem
				background #F64D47
				border-radius 0.213333rem
				margin auto
		.main-1
			padding-bottom 0.66rem
			.title
				padding 0.533333rem 0
			.list
				margin-bottom 0.426666rem
				height 8rem
				overflow-y scroll
				.van-list
					display flex
					flex-wrap wrap
					justify-content flex-start
					padding 0 0.213333rem
				.van-cell
					padding 0
					margin 0.106666rem 0
					width 25%
					background none
				.van-cell::after
					border none
			.list::-webkit-scrollbar
				display none
			/deep/.van-list__finished-text
				width 100%
				font-size 0.32rem
			/deep/.van-list__loading
				width 100%
			.item
				display flex
				flex-direction column
				align-items center
				p
					margin-top 2px
					font-size 0.32rem
					color #666666
				img
					width 1.12rem
					height 1.12rem
		.main-2
			padding 1.333333rem 0 0.66rem
			.btn
				margin-top  1.6rem
			.tips-1
				margin-top 0.8rem
				font-size 0.4rem
				color #666666
				font-weight 500
			.tips-2
				margin-top 0.4rem
				font-size 0.346666rem
				color #999999
		.main-3
			padding 1.4rem 0 1.6rem
			img
				margin-bottom 0.9rem
				width 3rem
	// 抽奖
	.horn-box
		position relative
		background linear-gradient(0deg, #FFCC89, #FFF5DE)
		border-radius 0.186666rem
		.horn
			position absolute
			top -3px
			width 0.48rem
			height 0.48rem
		.left
			left -3px
		.right
			right -3px
	.brand-box
		margin 0.64rem 0 
		padding 0.453333rem 0 0.586666rem
		.tips
			margin 0 0.32rem 0.426666rem
			height 1.2rem
			background url(../../assets/images/luckDraw/title-1.png)
			background-size 100% 100%
			.tips-1
				margin-bottom 6px
				font-size 0.4rem
				color #6B110A
				font-weight bold
			.tips-2
				font-size 0.426666rem
				color #000000
				font-weight 500
				span
					margin 0 8px
					color #FF0104
		ul
			display flex
			justify-content center
			align-items center
			flex-wrap wrap
			margin 0 0.8rem
			padding 5px 0
			background #B20A0C
			border-radius 0.186666rem
			li
				position relative
				padding 0.053333rem
				font-size 0
				img
					width 2.453333rem
					height 2.506666rem
				.hand
					position absolute
					bottom -0.906666rem
					right -1.226666rem
					width 2.08rem
					height 2.106666rem
					z-index 99
					animation handMove 0.8s infinite
				.card
					animation cardMove 2s
				.show-img
					position absolute
					top 0.053333rem
					left 0.053333rem
					width 2.453333rem
					height 2.506666rem
					background #fff
					border-radius 0.373333rem
					box-sizing border-box
					border 0.14rem solid #FFC332
					display flex
					flex-direction column
					justify-content center
					align-items center
					img
						width 1.3rem
						height 1.3rem
					p
						font-size 0.32rem
						color #333333
		@keyframes handMove
			0% { transform: translate(0px, 0px); }
			50% { transform: translate(15px, 20px); }
			100% { transform: translate(0px, 0px); }
		@keyframes cardMove
			0% { transform: rotateY(0deg); }
			100% { transform: rotateY(360deg); }
</style>
