<template>
	<div v-if="noticeList.length > 0" class="horn-box notice-box">
		<img class="horn left" src="@/assets/images/luckDraw/horn-left.png" alt="">
		<img class="horn right" src="@/assets/images/luckDraw/horn-right.png" alt="">
		<van-swipe class="notice-swipe" :autoplay="1800" loop vertical :show-indicators="false" :touchable="false">
			<van-swipe-item v-for="(item, index) in noticeList" :key="index" class="notice">
				<p><span>【{{item.userName}}】</span>刚刚充值<span> {{item.amount}}元</span>，获得<span>【{{item.chance}}】次机会</span></p>
			</van-swipe-item>
		</van-swipe>
	</div>
</template>

<script>
	import { getUserPartakeRecordsApi } from '@/request/api.js'
	export default {
		data() {
			return {
				noticeList: []
			}
		},
		methods: {
			getNotice() {
				getUserPartakeRecordsApi({
					pageNumber: 1,
					pageSize: 20
				}).then(res => {
					this.noticeList = res.data.records
				}).catch(err => {
					this.$toast(err.message)
				})
			}
		},
		mounted() {
			this.getNotice()
		}
	}
</script>

<style lang="stylus" scoped>
	.horn-box
		position relative
		background linear-gradient(0deg, #FFCC89, #FFF5DE)
		border-radius 0.186666rem
		.horn
			position absolute
			top -3px
			width 0.48rem
			height 0.48rem
		.left
			left -3px
		.right
			right -3px
	.notice-box
		display flex
		justify-content flex-start
		align-items center
		height 1.333333rem
		.notice-swipe
			box-sizing border-box
			padding 0 0.213333rem
			width 100%
			height 0.64rem
			.notice
				height 0.64rem
				line-height 0.78rem
				text-align left
				p 
					font-size 0.373333rem
					color #6B110A
					font-weight 500
					overflow hidden
					text-overflow ellipsis
					white-space nowrap
					span
						color #F43638
</style>
