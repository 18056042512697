<template>
	<div class="lump">
		<img class="title" src="@/assets/images/newyear/active-title.png" alt="">
		<div class="content">
			<p class="item mb">1、活动日期<span class="time">2022-04-30</span>日至<span class="time">2022-05-15</span>日，且本活动仅在此时间内有效；</p>
			<p class="item">2、活动结束后排行榜前十可获得礼物奖励，奖励自动发放。</p>
			<div class="total-num">
				<div class="column">
					<p>名次</p>
					<p>奖励1</p>
				</div>
				<div v-for="(item, index) in noList" :key="index" class="column blod">
					<p class="coffee">{{item.no}}</p>
					<p>{{item.gift}}</p>
				</div>
			</div>
			<p class="item mb">3、收到1钻石等于1女神值</p>
			<p class="item mb">4、女神指数只累计活动期间内收到钻石数，活动期间外收入钻石不计入收入累计。</p>
			<p class="item mb">5、钻石数相同情况（优先判断活动期间内先赚取到总钻石数，则排行在前）</p>
			<p class="item">6、本次活动所有最终解释权归平台所有。</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				noList: [
					{ no: '第一名', gift: '兰博基尼X3' },
					{ no: '第二名', gift: '兰博基尼X2' },
					{ no: '第三名', gift: '兰博基尼X1' },
					{ no: '第四名', gift: '牧马人X1' },
					{ no: '第五名', gift: '牧马人X1' },
					{ no: '第六名', gift: '牧马人X1' },
					{ no: '第七名', gift: '牧马人X1' },
					{ no: '第八名', gift: '牧马人X1' },
					{ no: '第九名', gift: '牧马人X1' },
					{ no: '第十名', gift: '牧马人X1' }
				]
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 0.48rem 0.346666rem 1.066666rem
		min-height 100vh
		box-sizing border-box
		background linear-gradient(0deg, #DD4743, #E98955)
		.title
			display block
			margin 0 auto 0.42rem
			width 6.533333rem
			height 1.333333rem
		.content
			padding 0.7rem 0.54rem 0.46rem
			background  #FFF0D4
			border-radius: 12px;
			text-align left
			box-shadow: 0px 6px 32px 0px #E6603C, 0px 0px 90px 0px #D53A36;
			.item
				font-size 0.35rem
				color #DC4539
				font-weight 500
				line-height 0.6rem
				.time
					margin 0 2px
					letter-spacing 1px
			.mr
				margin 0.12rem 0
			.mb 
				margin-bottom 0.4rem
			.total-num
				margin 0.2rem 0 0.4rem
				.column
					display flex
					justify-content center
					align-items center
					border-top 1px solid #FFB981
					height 0.8rem
					text-align center
					font-size 0.293333rem
					color #333333
					font-weight 500
					p 
						width 50%
						height 0.8rem
						line-height 0.9rem
						box-sizing border-box
						border-left 1px solid #FFB981
					.coffee
						color #845400
				.column:nth-last-child(1)
					border-bottom 1px solid #FFB981
				.column p:nth-last-child(1)
					border-right 1px solid #FFB981
				.blod
					font-weight bold
					font-size 0.32rem
</style>
