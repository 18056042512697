export function getFilter(gender, type) {
	console.log(type)
	let jsonData = ''
	if (gender == 1) {  // 女
		switch(type) {
			case 'isGuobai':
				jsonData = require('@/assets/json/question_helper_guo_bai_women.json');
				break;
			case 'isSimpleApp':
				jsonData = require('@/assets/json/question_helper_simple_women.json');
				break;
			case '20000':
				jsonData = require('@/assets/json/question_helper_women_20000.json');
				break;
			default:
				jsonData = require('@/assets/json/question_helper_women.json');
				break;
		}
	} else {
		switch(type) {
			case 'isGuobai':
				jsonData = require('@/assets/json/question_helper_guo_bai_man.json');
				break;
			case 'isSimpleApp':
				jsonData = require('@/assets/json/question_helper_simple_man.json');
				break;
			case '20000':
				jsonData = require('@/assets/json/question_helper_man_20000.json');
				break;
			default:
				jsonData = require('@/assets/json/question_helper_man.json');
				break;
		}
	}
	console.log(jsonData)
	return jsonData
}