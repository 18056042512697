<template>
	<div class="lump">
		<router-link to="/swindle">
			<img class="banner" src="@/assets/images/help/banner.png" alt="">
		</router-link>
		<p class="title">常见问题</p>
		<div class="tab-list">
			<div class="tab-item">
				<router-link :to="'/question?gender='+gender+'&appType='+appType+'&dataType=diamonds'">
					<img src="@/assets/images/help/diamonds.png" alt="">
					<p>{{gender == 1 ? '水晶问题' : '钻石问题'}}</p>
				</router-link>
			</div>
			<div class="tab-item">
				<router-link :to="'/question?gender='+gender+'&appType='+appType+'&dataType=recharge'">
					<img src="@/assets/images/help/recharge.png" alt="">
					<p>{{gender == 1 ? '提现问题' : '充值问题'}}</p>
				</router-link>
			</div>
			<div class="tab-item">
				<router-link :to="'/question?gender='+gender+'&appType='+appType+'&dataType=acount'">
					<img src="@/assets/images/help/acount.png" alt="">
					<p>{{gender == 1 ? '其它问题' : '账号问题'}}</p>
				</router-link>
			</div>
		</div>
		<p class="title">热门问题</p>
		<div class="question-box">
			<ul class="list">
				<li v-for="(item, index) in hotList" :key="index" @click="openTiem(index)">
					<div>
						<p>{{index + 1}}.{{item.title}}</p>
						<img src="@/assets/images/help/more.png" alt="">
					</div>
					<p v-show="active == index && show" class="tip">{{item.content}}</p>
				</li>
			</ul>
			<div @click="randomArr()" class="change-btn">
				<img src="@/assets/images/help/refresh.png" alt="">
				<p>换一批</p>
			</div>
		</div>
		<div class="consult-box">
			<div @click="openCustomerService" class="consult-btn">
				<img src="@/assets/images/help/online.png" alt="">
				<p>咨询在线客服</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { getFilter } from '@/plugins/common.js'
	export default {
		data() {
			return {
				gender: 0,     // 0：男    1：女
				appType: '',
				questionData: {},
				hotList: [],
				show: false,
				active: 0
			}
		},
		methods: {
			// 随机数组
			randomArr() {
				const arr = this.questionData.hot
				const count = this.gender == 1 ? 3 : 5
				const shuffled = arr.slice(0)
				let i = arr.length
				let min = i - count
				let temp = 0
				let index = 0
				while (i-- > min) {
					index = Math.floor((i + 1) * Math.random())
					temp = shuffled[index]
					shuffled[index] = shuffled[i]
					shuffled[i] = temp
				}
				this.hotList = shuffled.slice(min)
				console.log(this.hotList)
			},
			openTiem(index) {
				this.show = this.active != index ? true : !this.show
				this.active = index
			},
			openCustomerService() {
				let deviceInfo = navigator.userAgent.toLowerCase()
				if (deviceInfo.indexOf('android') != -1) {
					androidWebKit.goToCustomerService()
				} else if (deviceInfo.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.goToCustomerService.postMessage('咨询在线客服')
				}
			}
		},
		mounted() {
			this.gender = this.$route.query.gender
			this.appType = this.$route.query.appType
			this.questionData = getFilter(this.gender, this.appType)
			this.randomArr()
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 0 0.4rem 3.733333rem
		background #f6f6f6
		min-height 100vh
		box-sizing border-box
		.banner
			width 100%
		.title
			margin 0.373333rem 0 0.32rem
			font-size 0.4rem
			color #666262
			font-weight bold
			text-align left
		.tab-list
			display flex
			justify-content center
			align-items center
			background #fff
			border-radius 6px
			padding 0.426666rem 0 0.48rem
			.tab-item
				width 33.33%
				img
					width 0.746666rem
					height 0.8rem
				p
					margin-top 0.346666rem
					font-size 0.373333rem
					font-weight 400
					color #8b8b8b
		.question-box
			padding 0.48rem
			background #fff
			border-radius 6px
			text-align left
			.list
				
				li
					padding-bottom 0.2rem
					margin-bottom 0.32rem
					line-height 0.533333rem
					border-bottom 1px solid #f6f6f6
					div
						display flex
						justify-content space-between
						align-items center
						p
							font-size 0.373333rem
							color #5d82b9
							width 94%
							// overflow hidden
							// text-overflow ellipsis
							// white-space nowrap
						img
							width 0.213333rem
					.tip
						margin-top 0.266666rem
						font-size 0.373333rem
						color #666666
						line-height 0.586666rem
			.change-btn
				padding 0.266666rem 0 0.533333rem
				display flex
				justify-content center
				align-items center
				img
					margin-right 0.16rem
					width 0.4rem
				p
					font-size 0.373333rem
					color #CBCBE8
		.consult-box
			position fixed
			padding-bottom 1.386666rem
			left 0.4rem
			right 0.4rem
			bottom 0
			background #f6f6f6
			.consult-btn
				display flex
				justify-content center
				align-items center
				background #9E51EF
				border-radius 6px
				height 1.2rem
				img
					margin-right 0.266666rem
					width 0.533333rem
				p
					color #fff
					font-size 0.426666rem
</style>
