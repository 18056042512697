<template>
	<div class="btn">
		<p @click="openNotice">开通允许通知</p>
		<p @click="openShow" class="red">开启优先显示</p>
	</div>
</template>

<script>
	export default {
		props: ['flag'],
		data() {
			return {
				deviceInfo: ''
			}
		},
		methods: {
			openNotice() {
				// this.$toast('开通允许通知')
				if (this.deviceInfo.indexOf('android') != -1) {
					try {
						AndroidInterface.openNotification()
					} catch(error) {
						if (error && error.toString() == 'ReferenceError: AndroidInterface is not defined') {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
						} else {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
						}
					}
				} 
			},
			openShow() {
				// this.$toast(this.flag)
				// this.$toast('开启优先显示')
				if (this.deviceInfo.indexOf('android') != -1) {
					if (this.flag == 'true') {
						try {
							AndroidInterface.openDrawOverlaysPermission()
						} catch(error) {
							if (error && error.toString() == 'ReferenceError: AndroidInterface is not defined') {
								this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
							} else {
								this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
							}
						}
					} else {
						this.$toast('当前版本已支持')
					}
				} 
			}
		},
		mounted() {
			this.deviceInfo = navigator.userAgent.toLowerCase()
		}
	}
</script>

<style lang="stylus" scoped>
	.btn
		position fixed
		left 0
		right 0
		bottom 0
		display flex
		justify-content space-between
		padding 20px 14px 36px
		background #fff
		p
			padding 14px 0 13px
			width 4.2rem
			text-align center
			border-radius 0.533333rem
			font-size 0.4rem
			color #fff
			font-weight 500
			background #59AFFF
		.red
			background #FF647D
</style>
