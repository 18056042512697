<template>
	<ul class="list">
		<li v-for="(item, index) in questionList" :key="index" class="item">
			<p class="title">{{item.title}}</p>
			<p class="content">{{item.content}}</p>
		</li>
	</ul>
</template>

<script>
	import { getFilter } from '@/plugins/common.js'
	export default {
		data() {
			return {
				gender: 0,     // 0：男    1：女
				appType: '',
				dataType: '',
				questionData: {},
				questionList: []
			}
		},
		methods: {
			getFilter() {
				switch(this.dataType) {
					case 'diamonds':
						this.questionList = this.questionData.diamonds
						break;
					case 'recharge':
						this.questionList = this.questionData.recharge
						break;
					case 'acount':
						this.questionList = this.questionData.acount
						break;
				}
			}
		},
		created() {
			this.gender = this.$route.query.gender
			this.appType = this.$route.query.appType
			this.dataType = this.$route.query.dataType
			this.questionData = getFilter(this.gender, this.appType)
			this.getFilter()
			document.title = this.questionList[0].type
		}
	}
</script>

<style lang="stylus" scoped>
	.list
		padding 0.533333rem 0.8rem
		text-align left
		background #f6f6f6
		min-height 100vh
		box-sizing border-box
		.item
			margin-bottom 0.533333rem
			.title
				margin-bottom 12px
				font-size 0.426666rem
				color #5D82B9
			.content
				font-size 0.4rem
				color #666666
				line-height 0.586666rem
</style>
