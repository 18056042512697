<template>
	<div class="lump">
		<div class="total">
			<p class="p-1">钻石余额：</p>
			<img class="drill" src="@/assets/images/luckDraw/drill-01.png" alt="">
			<span>{{balanceCurrency}}</span>
			<p class="p-1">（</p>
			<img class="question" src="@/assets/images/luckDraw/question.png" alt="">
			<p class="p-1">赠送：</p>
			<span class="free">{{freeCurrency}}</span>
			<p class="p-1">）</p>
		</div>
		<ul class="list">
			<li v-for="(item, index) in payList" :key="index">
				<div @click="choicePay(index, item.rechargeCode)" class="item-box" :class="payIndex === index ? 'active' : ''">
					<div class="num-box">
						<p class="num" :class="item.flag ? '' : 'fff'">{{item.discount}}折优惠</p>
						<img src="@/assets/images/luckDraw/drill-02.png" alt="">
						<p class="drill">{{item.diamonds}}钻石</p>
						<p v-show="item.giveDiamonds != 0" class="free">赠送{{item.giveDiamonds}}钻石</p>
					</div>
					<p v-if="item.flag" class="price">¥{{item.currentPrice}}<span>¥{{item.originalPrice}}</span></p>
					<p v-else class="price">¥{{item.originalPrice}}</p>
				</div>
			</li>
		</ul>
		<p class="tips">如有问题请联系客服</p>
		<van-action-sheet v-model="payShow" class="pay-show">
			<div @click="choiceType(2)" class="pay-type border-b">
				<div>
					<img class="pay-img" src="@/assets/images/luckDraw/wx.png" alt="">
					<span>微信支付</span>
				</div>
				<img v-if="type == 2" class="choice-img" src="@/assets/images/luckDraw/choice-01.png" alt="">
				<img v-else class="choice-img" src="@/assets/images/luckDraw/choice-02.png" alt="">
			</div>
			<div @click="choiceType(1)" class="pay-type">
				<div>
					<img class="pay-img" src="@/assets/images/luckDraw/zfb.png" alt="">
					<span>支付宝支付</span>
				</div>
				<img v-if="type == 1" class="choice-img" src="@/assets/images/luckDraw/choice-01.png" alt="">
				<img v-else class="choice-img" src="@/assets/images/luckDraw/choice-02.png" alt="">
			</div>
			<p @click="payWay" class="sure">确认充值</p>
		</van-action-sheet>
	</div>
</template>

<script>
	import { getUserAccountApi, getQixiRechargeListApi, recordOrderNoApi } from '@/request/api.js'
	export default {
		data() {
			return {
				payShow: false,
				payList: [],
				payIndex: '',
				type: 2,
				code: '',
				balanceCurrency: 0,
				freeCurrency: 0,
				date: ''
			}
		},
		methods: {
			// 用户余额
			getUserAccount() {
				getUserAccountApi().then(res => {
					this.balanceCurrency = res.data.balanceCurrency
					this.freeCurrency = res.data.freeCurrency
				})
			},
			// 选择充值档位
			choicePay(index, code) {
				this.payIndex = index
				this.code = code
				this.payShow = true
			},
			// 选择支付方式
			choiceType(type) {
				this.type = type
			},
			getConfig() {
				getQixiRechargeListApi({date: this.date}).then(res => {
					this.payList = res.data
				}).catch(err => {
					this.$toast(err.message)
				})
			},
			// 调用安卓支付
			payWay() {
				try {
					androidWebKit.payNumber(this.type, this.code, 'json')
				} catch (error) {
					if (error && error.toString() == 'ReferenceError: androidWebKit is not defined') {
						this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
					} else {
						this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
					}
				}
			},
			// 安卓调用传入支付结果
			getPayResult(res) {
				let resArr = res.split('-')
				if (resArr[0] == 'true') {
					// let resJson = JSON.parse(resArr[1])
					this.payShow = false
					setTimeout(() => {
						this.$router.back(0)
					}, 1000)
					this.buryClick('约惠七夕充值成功次数', 'About_the_number_of_successful_recharge_on_Tanabata_male_click')
				} else {
					this.$toast('支付失败')
				}
			},
			// 调用埋点方法
			buryClick(name, type) {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						console.log(error.toString())
					}
				}
			}
		},
		mounted() {
			this.date = this.$route.query.date
			this.getUserAccount()
			this.getConfig()
			window.getPayResult = this.getPayResult
		}
	} 
</script>

<style lang="stylus" scoped>
	.lump 
		background #F4F4F4
		min-height 100vh
	.total 
		display flex
		justify-content flex-start
		align-items center
		padding 0.4rem 
		color #666666
		.drill
			margin-right 0.106666rem
			width 0.48rem
			height 0.426666rem
		.question	
			margin 0 0.16rem 0 0.053333rem
			width 0.4rem
			height 0.4rem
		span
			color #537CC8
			font-weight 600
			font-size 0.4rem
		.p-1
			position relative
			top 1px
	.tips 
		font-size 0.266666rem
		padding 1rem 0
		color #8C45CE
	.free 
		margin-left 0.053333rem
		font-size 0.4rem
	.list 
		display flex
		flex-wrap wrap
		justify-content flex-start
		padding 0 0.346666rem
		li 
			box-sizing border-box
			padding 0.266666rem
			width 33.33%
			.item-box 
				display flex
				flex-direction column
				justify-content space-between
				align-items center
				height 3.84rem
				background #fff
				border-radius 0.133333rem
				overflow hidden
				box-sizing border-box
				.price 
					width 100%
					height 0.826666rem
					background #8AC4EC
					font-size 0.4rem
					color #FFF
					font-weight 500
					line-height 1.013333rem
					span
						margin-left 4px
						font-size 0.293333rem
						text-decoration line-through
				.num-box
					padding-top 0.32rem
					.num
						font-size 0.293333rem
						color #8C45CE
						font-weight 500
					img
						margin 0.18rem 0 0.28rem
						width 0.72rem
						height 0.746666rem
					.drill
						color #4F86C2
						font-size 0.4rem
						font-weight 600
					.free
						margin-top 0.24rem
						font-size 0.293333rem
						color #DA767F
					.fff
						color #fff
			.active
				border 1px solid #1D50A1
				.price
					background #1D50A1
	.pay-show
		.pay-type
			display flex
			justify-content space-between
			align-items center
			margin 0 0.426666rem
			height 1.6rem
			font-size 0.4rem
			color #333333
			.pay-img
				margin-right 0.426666rem
				width 0.533333rem
				height 0.533333rem
			.choice-img
				width 0.506666rem
				height 0.506666rem
		.border-b
			border-bottom 1px solid #F4F4F4
		.sure
			padding 0.6rem 0
			background #1D50A1
			color #fff
			font-size 0.4rem
			font-weight 400
</style>
