<template>
	<div class="horn-box recharge">
		<img class="horn left" src="@/assets/images/luckDraw/horn-left.png" alt="">
		<img class="horn right" src="@/assets/images/luckDraw/horn-right.png" alt="">
		<img class="gold" src="@/assets/images/luckDraw/gold-2.png" alt="">
		<div class="how">
			<p>如何获得更多抽奖次数？</p>
			<img @click="goRecharge" src="@/assets/images/luckDraw/btn.png" alt="">
		</div>
		<ul>
			<li>19元 ≤ 充值金额 &lt; 49元，可进行<span>1次</span>抽奖</li>
			<li>49元 ≤ 充值金额 &lt; 99元，可进行<span>2次</span>抽奖</li>
			<li>99元 ≤ 充值金额 &lt; 199元，可进行<span>4次</span>抽奖</li>
			<li>199元 ≤ 充值金额 &lt; 399元，可进行<span>6次</span>抽奖</li>
			<li>399元 ≤ 充值金额 &lt; 699元，可进行<span>9次</span>抽奖</li>
			<li>699元 ≤ 充值金额 &lt; 999元，可进行<span>12次</span>抽奖</li>
			<li>999元 ≤ 充值金额 &lt; 1999元，可进行<span>14次</span>抽奖</li>
			<li>充值金额 ≥ 1999元，可进行<span>16次</span>抽奖</li>
		</ul>
	</div>
</template>

<script>
	export default {
		methods: {
			goRecharge() {
				this.buryClick('7月活动充值次数(充值按钮)', 'Flop_recharge_male_click')
				this.$router.push({ path: '/recharge' })
			},
			// 调用埋点方法
			buryClick(name, type) {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						if (error && error.toString() == 'ReferenceError: androidWebKit is not defined') {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服'})
						} else {
							this.$dialog.alert({confirmButtonText: '我知道了', message: '请您升级APP，如有疑问请咨询在线客服..'})
						}
					}
				}
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.horn-box
		position relative
		background linear-gradient(0deg, #FFCC89, #FFF5DE)
		border-radius 0.186666rem
		.horn
			position absolute
			top -3px
			width 0.48rem
			height 0.48rem
		.left
			left -3px
		.right
			right -3px
	.recharge
		margin-bottom 1.066666rem
		padding-bottom 0.48rem
		.gold
			position absolute
			bottom -0.533333rem
			left 0.213333rem
			width 1.626666rem
			height 1.546666rem
		.how 
			display flex
			justify-content space-between
			align-items flex-start
			p
				padding 0.67rem 0 0 0.67rem
				font-size 0.4rem
				color #6B110A
				font-weight bold
			img
				margin 0.24rem 0.24rem 0 0
				width 3.466666rem
				height 1.413333rem
		ul
			padding 0.373333rem 0.533333rem 0.613333rem
			margin 0.32rem 0.373333rem 0
			background #FFF2D9
			border-radius 0.186666rem
			li
				font-size 0.32rem
				color #711E16
				text-align left
				line-height 0.533333rem
				font-weight 500
				span
					color #F43638
</style>
