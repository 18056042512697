<template>
	<div v-if="recordList.length > 0" class="horn-box record-list">
		<img class="horn left" src="@/assets/images/luckDraw/horn-left.png" alt="">
		<img class="horn right" src="@/assets/images/luckDraw/horn-right.png" alt="">
		<img class="gold" src="@/assets/images/luckDraw/gold-1.png" alt="">
		<div class="tips">
			<p>中奖龙虎榜</p>
		</div>
		<div class="record-box">
			<swiper class="record-swiper swiper-no-swiping" :options="swiperOption" ref="mySwiper">
				<swiper-slide class="record-item" v-for="(item, index) in recordList" :key="index">
					恭喜<span>【{{item.userName}}】</span>获得<span>【{{item.prizeName}}】</span>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
	import { swiper, swiperSlide } from "vue-awesome-swiper"
	import "swiper/dist/css/swiper.css"
	import { getLotteryRecordsApi } from '@/request/api.js'
	export default {
		components: { swiper, swiperSlide },
		data() {
			return {
				recordList: [],
				swiperOption: {
					loop: true,
					direction: 'vertical',
					slidesPerView : 5,
					loopedSlides: 8,
					autoplay: {
						delay: 2000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
				}
			}
		},
		methods: {
			getRecordList() {
				getLotteryRecordsApi({
					pageNumber: 1,
					pageSize: 20
				}).then(res => {
					this.recordList = res.data.records
				}).catch(err => {
					this.$toast(err.message)
				})
			}
		},
		mounted() {
			this.getRecordList()
		}
	}
</script>

<style lang="stylus" scoped>
	.horn-box
		position relative
		background linear-gradient(0deg, #FFCC89, #FFF5DE)
		border-radius 0.186666rem
		.horn
			position absolute
			top -3px
			width 0.48rem
			height 0.48rem
		.left
			left -3px
		.right
			right -3px
	.record-list
		padding 0.24rem 0.373333rem 0.373333rem
		margin-bottom 0.906666rem
		.gold
			position absolute
			bottom -0.32rem
			right -0.106666rem
			width 1.386666rem
			height 1.12rem
		.tips
			display flex
			justify-content center
			align-items center
			margin 0 0.6rem 0.346666rem
			height 1.173333rem
			background url(../../../assets/images/luckDraw/title-2.png)
			background-size 100% 100%
			p
				font-size 0.48rem
				color #6B110A
				font-weight bold
		.record-box
			padding 0.3rem 0.533333rem
			background #FFF2D9
			border-radius 0.186666rem
			.record-swiper
				height 3rem
				.record-item
					font-size 0.32rem
					color #6B110A
					font-weight 500
					line-height 0.586666rem
					text-align left
					span
						color #F43638
</style>
