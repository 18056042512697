<template>
	<div class="lump">
		<top-title />
		<p class="step">第一步：打开“设置”</p>
		<img class="size-1" v-lazy="img1">
		<p class="step">第二步：点击“通知管理”</p>
		<img class="size-2" v-lazy="img2">
		<p class="step">第三步：点击“常驻通知”</p>
		<img class="size-2" v-lazy="img3">
		<p class="step">第四步：点击“通知”</p>
		<img class="size-2" v-lazy="img4">
		<bottom-btn :flag = 'flag' />
	</div>
</template>

<script>
	import topTitle from './components/topTitle.vue'
	import bottomBtn from './components/bottomBtn.vue'
	export default {
		components: { topTitle, bottomBtn },
		data() {
			return {
				flag: 'true',
				img1: require('@/assets/images/useExplain/meizu-01.png'),
				img2: require('@/assets/images/useExplain/meizu-02.png'),
				img3: require('@/assets/images/useExplain/meizu-03.png'),
				img4: require('@/assets/images/useExplain/meizu-04.png')
			}
		},
		mounted() {
			this.flag = this.$route.query.flag
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding-bottom 2.613333rem
		background #fff url(../../assets/images/useExplain/top-bj.png)
		background-repeat no-repeat
		background-size 100% auto
		.step
			padding 14px 14px 13px
			margin 0 auto 0.533333rem
			width -webkit-max-content
			font-size 0.373333rem
			color #fff
			font-weight 500
			border-radius 0.533333rem
			background #903DDD
		.size-1
			margin-bottom 0.8rem
			width 4rem
		.size-2
			margin-bottom 0.8rem
			width 5.6rem
		.describe
			padding 0 0.426666rem
			margin-bottom 0.533333rem
			font-size 0.373333rem
			color #333333
			font-weight 400
			line-height 0.533333rem
	.tips
		margin-bottom 0.6rem
		font-size 0.373333rem
		color #F1214E
		font-weight 400
		line-height 0.6rem
</style>
