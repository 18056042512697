<template>
	<van-dialog v-model="resultShow" :showConfirmButton="false" :closeOnClickOverlay="false" width="80%">
		<div class="result-dialog">
			<p class="title">{{resultList[mingci].rank}}</p>
			<div v-if="mingci == 3" class="card-box">
				<div class="card">
					<img class="img-02" src="@/assets/images/valentineDay/card-10.png" alt="">
					<p>{{resultList[mingci].num}}钻石</p>
				</div>
			</div>
			<div v-else class="card-box">
				<div class="card">
					<img class="img-01" src="@/assets/images/valentineDay/card-09.png" alt="">
					<p>红包{{resultList[mingci].num}}元</p>
				</div>
				<img class="add-icon" src="@/assets/images/valentineDay/add-icon.png" alt="">
				<div class="card card-02">
					<img class="img-02" src="@/assets/images/valentineDay/card-10.png" alt="">
					<p>{{resultList[mingci].num}}钻石</p>
				</div>
			</div>
			<img @click="getNo" class="btn" src="@/assets/images/valentineDay/btn-02.png" alt="">
		</div>
	</van-dialog>
</template>

<script>
	export default {
		data() {
			return {
				deviceInfo: '',
				mingci: 0,
				resultShow: true,
				resultList: [
					{ rank: '第一名', num: 3344 },
					{ rank: '第二名', num: 1314 },
					{ rank: '第三名', num: 520 },
					{ rank: '恭喜您获得', num: 520 }
				]
			}
		},
		methods: {
			getNo() {
				if (this.deviceInfo.indexOf('android') != -1) {
					androidWebKit.close()
					this.$toast('安卓')
				} else if (this.deviceInfo.indexOf('iphone') != -1) {
					window.webkit.messageHandlers.closeWindow.postMessage("关闭窗口")
					this.$toast('苹果')
				}
			}
		},
		mounted() {
			this.deviceInfo = navigator.userAgent.toLowerCase()
			this.mingci = parseInt(this.$route.query.mingci)
			console.log(typeof(this.mingci))
		}
	}
</script>

<style lang="stylus" scoped>
	.result-dialog
		padding 0.8rem 0 0.64rem
		display flex
		flex-direction column
		justify-content center
		align-items center
		.title
			font-size 0.453333rem
			color #000000
			font-weight bold
		.btn
			width 3.68rem
			height 0.906666rem
		.card-box
			display flex
			justify-content center
			align-items flex-start
			margin 1rem 0 0.98rem
			.card
				padding 0 0.2rem
				.img-01
					width 0.986666rem
					height 1.36rem
				.img-02
					width 1.36rem
					height 1.36rem
				p
					margin-top 0.3rem
					font-size 0.32rem
					color #333333
			.card-02
				padding 0 0.2rem 0 0.4rem
			.add-icon
				margin-top 0.38rem
				width 0.38rem
				height 0.38rem
</style>
