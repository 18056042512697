<template>
	<div class="lump" :class="resultShow || ruleShow ? 'fixed' : ''">
		<div class="time-box">
			<img src="@/assets/images/valentineDay/time.png" alt="">
			<p v-show="tabState" @click="ruleShow = true">签到规则</p>
			<p v-show="!tabState" @click="ruleShow = true">排行规则</p>
		</div>
		<div class="main">
			<div class="tab-box">
				<img v-show="tabState" src="@/assets/images/valentineDay/tab-1.png" alt="">
				<img @click="changeTab" v-show="!tabState" src="@/assets/images/valentineDay/tab-3.png" alt="">
				<img @click="changeTab" v-show="tabState" src="@/assets/images/valentineDay/tab-2.png" alt="">
				<img v-show="!tabState" src="@/assets/images/valentineDay/tab-4.png" alt="">
			</div>
			<div v-show="tabState" class="sign-list">
				<div v-for="(item, index) in signInList" :key="index" class="item">
					<div class="card-box">
						<p class="day">{{item.dayText}}</p>
						<div class="card">
							<img :src="item.soundCardPic" alt="">
							<p>{{item.soundCardText}}</p>
						</div>
						<img class="add-icon" src="@/assets/images/valentineDay/add-icon.png" alt="">
						<div class="card">
							<img :src="item.discountCardPic" alt="">
							<p>{{item.discountCardText}}</p>
						</div>
					</div>
					<p v-show="item.status == 1" @click="getCard(item.id, index)" class="get-btn btn01">点击领取</p>
					<p v-show="item.status == 2 || item.status == 3" @click="goRecharge(item.id)" class="get-btn btn02">点击充值</p>
					<p v-show="item.status == 0" class="get-btn btn03">点击领取</p>
				</div>
			</div>
			<div v-show="!tabState" class="love-list">
				<div v-if="rankList.length > 0" class="top-three">
					<div class="item2">
						<img class="crown" src="@/assets/images/valentineDay/rank-2.png" >
						<div class="head-box">
							<img class="boy" :src="'http://qn.qs520.mobi/' + rankList[1].relationAvatar" :onerror="errorImage">
							<img class="girl" :src="'http://qn.qs520.mobi/' + rankList[1].avatar" :onerror="errorImage">
						</div>
						<div class="name">
							<p>{{rankList[1].nickName}}</p>
							<p>{{rankList[1].relationName}}</p>
						</div>
						<p class="total">缘分值：{{rankList[1].luckCount}}</p>
					</div>
					<div class="item1 width4">
						<img class="crown" src="@/assets/images/valentineDay/rank-1.png" >
						<div class="head-box">
							<img class="boy" :src="'http://qn.qs520.mobi/' + rankList[0].relationAvatar" :onerror="errorImage">
							<img class="girl" :src="'http://qn.qs520.mobi/' + rankList[0].avatar" :onerror="errorImage">
						</div>
						<div class="name">
							<p>{{rankList[0].nickName}}</p>
							<p>{{rankList[0].relationName}}</p>
						</div>
						<p class="total">缘分值：{{rankList[0].luckCount}}</p>
					</div>
					<div class="item3">
						<img class="crown" src="@/assets/images/valentineDay/rank-3.png" >
						<div class="head-box">
							<img class="boy" :src="'http://qn.qs520.mobi/' + rankList[2].relationAvatar" :onerror="errorImage">
							<img class="girl" :src="'http://qn.qs520.mobi/' + rankList[2].avatar" :onerror="errorImage">
						</div>
						<div class="name">
							<p>{{rankList[2].nickName}}</p>
							<p>{{rankList[2].relationName}}</p>
						</div>
						<p class="total">缘分值：{{rankList[2].luckCount}}</p>
					</div>
				</div>
				<div v-if="rankList.length > 0" class="list">
					<van-list v-model="loading" :finished="finished" :immediate-check ='false' finished-text="没有更多了~" class="list-item" @load="getRank">
						<van-cell v-for="(item, index) in rankList" :key="index">
							<div v-if="index > 2" class="rank-item">
								<div class="rank">
									<p class="number">{{index+1}}</p>
									<div class="head-box">
										<img class="head-pic" :src="'http://qn.qs520.mobi/' + item.avatar" :onerror="errorImage">
										<img class="head-pic girl" :src="'http://qn.qs520.mobi/' + item.relationAvatar" :onerror="errorImage">
									</div>
									<div class="name">
										<p>{{item.nickName}}</p>
										<p>{{item.relationName}}</p>
									</div>
								</div>
								<p class="total">缘分值：{{item.luckCount}}</p>
							</div>
						</van-cell>
					</van-list>
				</div>
			</div>
		</div>
		<van-dialog v-model="resultShow" :showConfirmButton="false" closeOnClickOverlay width="80%">
			<div class="result-dialog">
				<p class="title">恭喜你获得</p>
				<div class="card-box">
					<div class="card">
						<img :src="resultInfo.soundCardPic" alt="">
						<p>{{resultInfo.soundCardText}}</p>
					</div>
					<img class="add-icon" src="@/assets/images/valentineDay/add-icon.png" alt="">
					<div class="card">
						<img :src="resultInfo.discountCardPic" alt="">
						<p>{{resultInfo.discountCardText}}</p>
					</div>
				</div>
				<img @click="goRecharge(resultInfo.id)" class="btn" src="@/assets/images/valentineDay/btn-03.png" alt="">
			</div>
		</van-dialog>
		<van-dialog v-model="ruleShow" :showConfirmButton="false" closeOnClickOverlay width="85%">
			<div class="rule-dialog">
				<p v-show="tabState" class="title">签到规则</p>
				<p v-show="!tabState" class="title">排行规则</p>
				<div v-show="tabState" class="tips">
					<p>1、每日不中断签到可获得豪礼。中途未登录，重新计算。<br>奖励内容：</p>
					<p class="em">a. 活动当天领取<span>语音卡1张+原价19元充值9.5折充值折扣劵1张</span>;</p>
					<p class="em">b. 连续签到2天，领取<span>语音卡2张+原价49元充值9折充值折扣券1张</span>;</p>
					<p class="em">c. 连续签到3天，领取<span>语音卡3张+原价99元充值8.5折充值折扣券1张</span>;</p>
					<p class="em">d. 连续签到4天，领取<span>语音卡4张+原价199元充值8折充值折扣劵1张</span>;</p>
					<p class="em">e. 连续签到5天，领取<span>语音卡5张+原价399元充值7.5折充值折扣1张</span>;</p>
					<p class="em">f. 连续签到6天，领取<span>语音卡6张+原价699元充值7折充值折扣1张</span>;</p>
					<p class="em">g. 连续签到7天，领取<span>语音卡7张+原价999元充值6折充值折扣1张</span>;</p>
					<p class="two">2、本次活动最终解释权归平台所有，在法律允许范围内，任何问题以官方解释为准。</p>
				</div>
				<div v-show="!tabState" class="tips">
					<p>1、在活动期间内，取缘分值前77对新人排名。</p>
					<p>2、第一名：“亲密无间”男女用户各获得现金<span>红包3344元+3344钻石。</span></p>
					<p>3、第二名：“真爱永恒”男女用户各获得现金<span>红包1314元+1314钻石。</span></p>
					<p>4、第三名：“最爱七夕”男女用户各获得现金<span>红包520元+520钻石。</span></p>
					<p>5、其它参与人员（亲密度前74对新人）每人赠送<span>520钻石。</span></p>
					<p>6、在活动期间内消费<span>1钻石等于1缘分值。</span></p>
					<p>7、本次活动最终解释权归平台所有，在法律允许范围内，任何问题以官方解释为准。</p>
				</div>
				<img @click="ruleShow = false" class="btn" src="@/assets/images/valentineDay/btn-02.png" alt="">
			</div>
		</van-dialog>
	</div>
</template>

<script>
	import { getQixiRankListApi, getQixiSignInListApi, getQixiReceiveApi } from '@/request/api.js'
	import Cookies from 'js-cookie'
	export default {
		data() {
			return {
				tabState: true,
				resultShow: false,
				ruleShow: false,
				formData: {
					current: 1,
					size: 11
				},
				rankList: [],
				signInList: [],
				loading: false,
				finished: false,
				errorImage: 'this.src="' + require('@/assets/images/valentineDay/default-pic.png') + '"',
				resultInfo: {},
				flag: true
			}
		},
		methods: {
			changeTab() {
				this.tabState = !this.tabState
				if (this.tabState) {
					this.initData()
					this.buryClick("约惠七夕次数", "Times_of_Chinese_Valentines_Day_male_click")
				} else {
					this.getSignInList()
					this.buryClick("最爱七夕次数", "Favorite_times_of_Tanabata_male_click")
				}
			},
			// 去充值
			goRecharge(id) {
				this.$router.push({
					path: '/recharge',
					query: {
						date: id
					}
				})
			},
			initData() {
				this.finished = false
				this.formData = {
					current: 1,
					size: 11
				}
				this.rankList = []
				this.getRank()
			},
			// 排行列表
			getRank() {
				getQixiRankListApi(this.formData).then(res =>{
					if(this.rankList.length === res.data.total) {
						this.finished = true
					} else {
						this.formData.current ++
						res.data.records.forEach((item, index) => {
							this.rankList.push(item)
						})
						this.loading = false
					}
				}).catch(err => {
					this.finished = true
					this.loading = false
					this.$toast(err.message)
					console.log(err.message)
				})
			},
			// 签到列表
			getSignInList() {
				getQixiSignInListApi().then(res => {
					this.signInList = res.data
				}).catch(err =>{
					this.$toast(err.message)
				})
			},
			// 签到
			getCard(id, index) {
				if (this.flag) {
					this.flag = false
					getQixiReceiveApi({ date: id}).then(res => {
						setTimeout(() => {
							this.flag = true
						}, 1000)
						this.resultInfo = this.signInList[index]
						this.resultShow = true
						this.getSignInList()
						this.buryClick("签到成功", "Check_in_succeeded_click")
					}).catch(err => {
						this.flag = true
						this.$toast(err.message)
					})
				}
			},
			// 调用埋点方法
			buryClick(name, type) {
				let ua = navigator.userAgent.toLowerCase()
				if (ua.indexOf('android') != -1) {
					try {
						androidWebKit.onclikType(name, type)
					} catch (error) {
						console.log(error.toString())
					}
				}
			}
		},
		mounted() {
			this.token = this.$route.query.token
			Cookies.set('token', this.token, { expires: 1 })
			this.getSignInList()
			this.getRank()
		}
	}
</script>

<style lang="stylus" scoped>
	.result-dialog
		padding 0.8rem 0 0.64rem
		display flex
		flex-direction column
		justify-content center
		align-items center
		.title
			font-size 0.453333rem
			color #000000
			font-weight 500
		.btn
			width 3.68rem
			height 0.906666rem
		.card-box
			display flex
			justify-content center
			align-items flex-start
			margin 1rem 0 0.98rem
			.card
				padding 0 0.3rem
				img
					width 1.76rem
					height 1.066666rem
				p
					margin-top 0.3rem
					font-size 0.32rem
					color #333333
			.add-icon
				margin-top 0.38rem
				width 0.38rem
				height 0.38rem
	.rule-dialog
		padding 0.8rem 0.8rem 0.533333rem
		.title
			font-size 0.453333rem
			color #000000
			font-weight 500
		.btn
			width 3.68rem
			height 0.906666rem
		.tips
			padding 0.533333rem 0 0.666666rem
			text-align left
			p
				font-size 0.346666rem
				color #666666
				line-height 0.533333rem
				span
					color #FF3B3B
					letter-spacing 1px
			.two
				margin-top 0.693333rem
			.em
				text-indent 2em
	.fixed
		position fixed
		top 0
		right 0
		bottom 0
		left 0
	.lump
		padding-bottom 0.4rem
		min-height 100vh
		background #FFE2E4 url(../../assets/images/valentineDay/main-banner.png)
		background-position top
		background-size 100% auto
		background-repeat no-repeat
		.time-box
			position relative
			padding 1.066666rem 0 4.533333rem
			img
				display block
				margin auto
				width 7.146666rem
				height 1.68rem
			p
				position absolute
				right 0
				top 2.666666rem
				padding 0.213333rem 0.16rem 0.186666rem 0.266666rem
				background #F66A6D
				border-radius 0.373333rem 0 0 0.373333rem
				color #fff
				font-size 0.346666rem
	.main
		margin 0 0.4rem 0
		min-height 60vh
		background #FFFEF5
		border 1px solid #FF8790
		box-shadow 0px 1px 6px 0px rgba(244,103,137,0.2700)
		border-radius 0.266666rem
		.tab-box
			display flex
			justify-content space-between
			padding 0.533333rem
			img
				width 3.52rem
				height 0.96rem
		.sign-list
			padding 0.213333rem 0.4rem 0
			.item
				display flex
				justify-content space-between
				align-items flex-start
				margin-bottom 0.8rem
				.card-box
					display flex
					justify-content space-between
					align-items flex-start
					.day
						margin 0.36rem 0.266666rem 0 0
						font-size 0.346666rem
						color #EA3B64
						font-weight 600
					.card
						img
							width 1.653333rem
							height 0.986666rem
						p
							margin-top 0.266666rem
							font-size 0.32rem
							color #333333
					.add-icon
						margin 0.32rem 0.266666rem 0
						width 0.38rem
						height 0.38rem
				.get-btn
					margin-top 0.25rem
					padding 0.186666rem 0.293333rem
					border-radius 0.346666rem
					font-size 0.32rem
				.btn01
					background linear-gradient(0deg, #F04057 0%, #FF8595 100%)
					color #fff
				.btn02
					box-sizing border-box
					background #fff
					color #F14259
					border 1px solid #F14259
				.btn03
					background linear-gradient(0deg, #898989 0%, #C9C9C9 100%)
					color #fff
		.love-list
			position relative
			.list
				padding 0 0.2rem
				max-height 14.4rem
				overflow-y scroll
				.van-cell
					padding 0
					background none
				.van-cell::after
					border none
			.list::-webkit-scrollbar
				display none
			.list-item
				padding 0 0.533333rem
				.rank-item
					display flex
					justify-content space-between
					align-items center
					padding 0.373333rem 0.4rem 0.373333rem 0
					margin-bottom 0.4rem
					border-radius 0.8rem
					background #FFEAEC
					.rank, .head-box
						display flex
						justify-content flex-start
						align-items center
					.number
						width 1.333333rem
						font-size 0.48rem
						color #FF5565
						text-align center
					.head-pic
						border-radius 50%
						width 0.853333rem
						height 0.853333rem
					.girl
						position relative
						left -0.266666rem
					.name
						font-size 0.32rem
						color #AA0D1C
						line-height 0.48rem
					.total
						font-size 0.266666rem
						color #FF5565
			.top-three
				display flex
				justify-content center
				align-items center
				margin-bottom 0.8rem
				.crown
					width 0.88rem
					height 0.8rem
				.name
					font-weight bold
					line-height 0.48rem
				.head-box
					position relative
					margin-bottom 0.32rem
				.total
					margin-top 0.106666rem
				.item2, .item3
					width 33%
					font-size 0.32rem
					.crown
						position relative
						top 0.24rem
						z-index 3
					.head-box
						height 1.333333rem
						img
							position absolute
							width 1.253333rem
							height 1.253333rem
							border-radius 50%
							border 3px solid #8D8D8D
						.boy
							z-index 2
							left 50%
							transform translateX(-10px)
						.girl
							right 50%
							transform translateX(10px)
				.item1
					position relative
					top -0.4rem
					width 34%
					color #E23142
					.crown
						position relative
						top 0.24rem
						z-index 3
					.head-box
						height 1.493333rem
						img
							position absolute
							width 1.306666rem
							height 1.253333rem
							border-radius 50%
							border 0.08rem solid #E23142
						.boy
							z-index 2
							left 50%
							transform translateX(-10px)
						.girl
							right 50%
							transform translateX(10px)
				.item3 .head-box img
					border 0.08rem solid #01ECE6
				.item1 .name
					font-size 0.346666rem
				.item2 .name, .item2 .total
					color #8D8D8D
				.item3 .name, .item3 .total
					color #01ECE6
</style>
